import { useCallback, useEffect, useState } from 'react'
import { login } from 'src/api'
import { useNavigate, useParams } from 'react-router-dom'
import { message } from 'antd'

export const useViewModel = () => {
  const navigate = useNavigate()
  const { stnId } = useParams<{ stnId: string }>()
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const signIn = async () => {
    try {
      await login({ username, password })
      navigate('/')
    } catch (error) {
      message.error('Invalid username or password')
    }
  }

  return {
    username,
    setUsername,
    password,
    setPassword,
    signIn,
  }
}
