import classNames from 'classnames'
import Styles from './ControlButton.module.scss'
import { ReactNode } from 'react'
import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCloud,
  faDroplet,
  faTachometerAlt,
  faTemperatureQuarter,
  faWind,
} from '@fortawesome/free-solid-svg-icons'
import { MapLayer } from '../../meta'
import { Tooltip } from 'antd'

export const ControlButton = ({
  className,
  icon,
  iconName,
  selected,
  tooltip,
  onClick,
}: {
  className?: string
  icon?: ReactNode
  iconName?: IconDefinition
  selected?: any
  tooltip?: any
  onClick: () => any
}) => {
  return (
    <Tooltip title={tooltip} placement="left">
      <div
        className={classNames(Styles.ctrBtn, selected && Styles.selected, className)}
        onClick={onClick}
      >
        {icon && icon}
        {iconName && <FontAwesomeIcon icon={iconName} />}
      </div>
    </Tooltip>
  )
}

export const ControlButtonsGroup = ({
  className,
  buttons,
}: {
  className?: string
  buttons: ReactNode[]
}) => {
  return <div className={classNames(Styles.ctrBtnGroup, className)}>{buttons}</div>
}
export const CtrBtnIcons: Partial<Record<MapLayer, IconDefinition>> = {
  temperature: faTemperatureQuarter,
  humidity: faDroplet,
  cloud: faCloud,
  pressure: faTachometerAlt,
  wind: faWind,
}
