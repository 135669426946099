import { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'

export const usePlayer = ({
  step,
  stepUnit,
  interval,
  timeRange: [start, end],
}: {
  step: number
  stepUnit: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year'
  interval: number
  timeRange: [Dayjs, Dayjs]
}) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [current, setCurrent] = useState(end)

  useEffect(() => {
    if (!isPlaying) return
    const timer = setInterval(() => {
      setCurrent(current => {
        if (current.isSame(end) || current.isAfter(end)) {
          setIsPlaying(false)
          return current
        }
        return current.add(step, stepUnit)
      })
    }, interval)
    return () => clearInterval(timer)
  }, [isPlaying, interval])

  return {
    togglePlay: () => setIsPlaying(v => !v),
    isPlaying,
    current,
    setCurrent,
  }
}
