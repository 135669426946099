import { useEffect } from 'react'

export const useTimer = (
  callback: () => any,
  {
    interval,
  }: {
    interval: number
  },
) => {
  useEffect(() => {
    const timer = setInterval(() => {
      callback()
    }, interval)
    return () => clearInterval(timer)
  }, [interval])
}
