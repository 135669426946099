import { Slider as AntdSlider } from 'antd'
import Styles from './Slider.module.scss'
import 'maplibre-gl/dist/maplibre-gl.css'
import { range } from 'lodash'
import dayjs, { Dayjs } from 'dayjs'

export const Slider: React.FC<{
  timeRange?: [Dayjs, Dayjs]
  stepInMinutes?: number
  selected?: Dayjs
  onSelect: (time: Dayjs) => any
}> = ({ timeRange, stepInMinutes = 5, selected, onSelect }) => {
  const [min, max] = (timeRange ?? [dayjs().subtract(1, 'day'), dayjs()])?.map(v =>
    v.toDate().getTime()
  )

  return (
    <div className={Styles.slider}>
      <AntdSlider
        max={max}
        min={min}
        step={stepInMinutes * 60_000}
        tooltip={{
          formatter: t => dayjs(t).format('DD MMM HH:mm'),
        }}
        value={selected?.toDate().getTime() ?? max}
        onChange={v => onSelect(dayjs(new Date(v)))}
      />
      <div className={Styles.markers}>
        {range(0, 25).map(i => (
          <div key={i} />
        ))}
      </div>
    </div>
  )
}
