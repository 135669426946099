import { Button, DatePicker, Modal, Radio, Select, Spin, Tooltip } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import Styles from './HomePage.module.scss'
import Logo from 'src/assets/logo.png'
import Map, { Source, Layer } from 'react-map-gl/maplibre'
import 'maplibre-gl/dist/maplibre-gl.css'
import maplibregl from 'maplibre-gl'
import { ReactComponent as CompassIcon } from 'src/assets/compass.svg'
import { ControlButton, ControlButtonsGroup, CtrBtnIcons } from './ControlButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCaretDown,
  faPlus,
  faMinus,
  faLayerGroup,
  faLocationCrosshairs,
  faPlay,
  faPause,
  faUserCircle,
  faWalking,
  faClose,
  faCirclePlay,
  faBackwardFast,
  faForwardFast,
  faDownload,
} from '@fortawesome/free-solid-svg-icons'
import { useViewModel, DownloadRange } from './HomePage.vm'
import { useMemo, useRef } from 'react'
import {
  ChartLineColors,
  ChartTitle,
  ChartUnit,
  DistrictLayerConfigEntries,
  DistrictLayerHoverStyle,
  DistrictLayerStyle,
  DistrictNameEntries,
  Districts,
  MapConfig,
  MapLayers,
  MapStyleJson,
  MapStyles,
} from '../../meta'
import { ObsMarker } from './ObsMarker'
import { Chart } from 'src/components/Chart'
import { isEmpty, isNil } from 'lodash'
import { Slider } from './Slider'
import { useNavigate } from 'react-router-dom'
import { getCurrentUser } from 'src/auth'
import RainJson from './now.json'

const DownloadOptions: { label: string; value: DownloadRange }[] = [
  { label: 'Today', value: 'today' },
  { label: 'Yesterday', value: 'yesterday' },
  { label: 'Last 24 hours', value: 'last-24h' },
  { label: 'Last 7 days', value: 'last-7d' },
  { label: 'Custom', value: 'custom' },
]
export const HomePage = () => {
  const mapRef = useRef(null)
  const {
    chartData,
    obsMarkersShown,
    selectedLayer,
    hoveringFeature,
    zoomLevel,
    rotateDegree,
    tiltDegree,
    selectedTime,
    selectedStation,
    mapStyle,
    timeRange,
    playSpeed,
    isPlaying,
    downloadSelection,
    chartZoom,
    setCurrentChartX,
    zoomChartWhenFocus,
    goToLatest,
    toggleDownload,
    onDownloadSelectRange,
    onDownload,
    togglePlay,
    setMapStyle,
    selectStation,
    setSelectedTime,
    onZoom,
    onRotate,
    onTilt,
    clickCtrBtn,
    setHoveringFeature,
    goToDistrict,
    nextPlaySpeed,
    signOut,
  } = useViewModel()
  const navigate = useNavigate()

  const LayerCtrBtns = useMemo(
    () =>
      MapLayers.map((ly, i) => (
        <ControlButton
          key={`ly${i}`}
          onClick={() => clickCtrBtn(ly)}
          selected={selectedLayer === ly}
          iconName={CtrBtnIcons[ly]}
          tooltip={ly}
        />
      )),
    [selectedLayer, clickCtrBtn],
  )

  const onHover = (id: string) => {
    setHoveringFeature(id)
  }

  return (
    <div className={Styles.page}>
      <div className={Styles.map}>
        <Map
          ref={mapRef}
          mapLib={maplibregl}
          initialViewState={MapConfig}
          style={{
            width: '100vw',
            height: '100vh',
            cursor: hoveringFeature ? 'pointer' : 'grab',
          }}
          mapStyle={MapStyleJson[mapStyle]}
          onRotate={e => {
            onRotate(e.target.getBearing())
            onTilt(e.target.getPitch())
          }}
          onZoom={e => onZoom(e.target.getZoom())}
          interactiveLayerIds={Districts}
          onClick={e => goToDistrict(e.features?.[0]?.properties?.areaId, mapRef.current as any)}
          onMouseMove={e => onHover(e.features?.[0]?.properties?.areaId)}
          attributionControl={false}
        >
          {(zoomLevel ?? 0) < 12 &&
            DistrictLayerConfigEntries.map(([d, json], i) => (
              <Source type="geojson" data={json} key={`s-${i}`}>
                <Layer
                  id={d}
                  type="fill"
                  paint={hoveringFeature === d ? DistrictLayerHoverStyle : DistrictLayerStyle}
                />
              </Source>
            ))}
          {selectedLayer === 'cloud' && (
            <Source type="geojson" data={RainJson}>
              <Layer
                id="rain"
                type="fill-extrusion"
                paint={{
                  'fill-extrusion-color': ['get', 'color'],
                  'fill-extrusion-opacity': ['interpolate', ['linear'], ['zoom'], 8, 0.7, 14, 0.1],
                  'fill-extrusion-height': ['*', 50, ['get', 'intensity']],
                }}
              />
            </Source>
          )}
          {selectedLayer !== 'cloud' &&
            obsMarkersShown?.map((data, i) => (
              <ObsMarker
                key={`m-${i}`}
                data={data}
                selectedLayer={selectedLayer ?? 'temperature'}
                isSelected={selectedStation?.id === data.id}
                onClick={v => selectStation({ stationId: v.id?.toString() })}
                onCancel={() => selectStation({})}
              />
            ))}
        </Map>
        <div className={Styles.controls}>
          <ControlButtonsGroup
            buttons={[
              <ControlButton
                key="cb0"
                onClick={() => clickCtrBtn('zoom-in', mapRef.current as any)}
                tooltip={'zoom in'}
                iconName={faPlus}
              />,
              <ControlButton
                key="cb1"
                onClick={() => clickCtrBtn('zoom-out', mapRef.current as any)}
                tooltip={'zoom out'}
                iconName={faMinus}
              />,
              <ControlButton
                key="cb2"
                onClick={() => clickCtrBtn('direction', mapRef.current as any)}
                tooltip={'reset North'}
                icon={
                  <CompassIcon
                    className={Styles.compass}
                    style={{ transform: `rotate(${-1 * rotateDegree}deg)` }}
                  />
                }
              />,
            ]}
          />
          <ControlButton
            key="cb3"
            onClick={() => clickCtrBtn('locate', mapRef.current as any)}
            tooltip={'find my location'}
            iconName={faLocationCrosshairs}
          />
          <ControlButton
            key="cb4"
            onClick={() => clickCtrBtn('3D', mapRef.current as any)}
            tooltip={'tilt'}
            selected={tiltDegree}
            icon={
              <label style={{ transform: `rotate3d(1, 0, 0, ${tiltDegree}deg)` }}>{'3D'}</label>
            }
          />
          {LayerCtrBtns}
        </div>
        {isNil(obsMarkersShown) && (
          <div className={Styles.loadingMask}>
            <Spin
              size="large"
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              spinning
            />
          </div>
        )}
      </div>
      <div className={Styles.actionBar}>
        <img src={Logo} />
        <Select
          className={Styles.select}
          placeholder={
            <>
              <FontAwesomeIcon icon={faLayerGroup} />
              {'Theme'}
            </>
          }
          suffixIcon={<FontAwesomeIcon icon={faCaretDown} />}
          options={MapStyles.map(v => ({ label: v, value: v }))}
          value={null}
          onSelect={v => setMapStyle(v as any)}
        />
        <Select
          className={Styles.select}
          placeholder={
            <>
              <FontAwesomeIcon icon={faWalking} />
              {'Go To'}
            </>
          }
          suffixIcon={<FontAwesomeIcon icon={faCaretDown} />}
          popupMatchSelectWidth={false}
          options={DistrictNameEntries.map(([k, v]) => ({
            label: v,
            value: k,
          }))}
          value={null}
          onSelect={d => goToDistrict(d, mapRef?.current as any)}
        />
        <Select
          className={Styles.select}
          placeholder={
            <>
              <FontAwesomeIcon icon={faUserCircle} />
              {getCurrentUser()}
            </>
          }
          suffixIcon={<FontAwesomeIcon icon={faCaretDown} />}
          options={[{ label: 'Sign-out', value: 'sign-out' }]}
          onSelect={signOut}
        />
      </div>
      <div className={Styles.dataControl} onWheel={zoomChartWhenFocus as any}>
        {selectedStation && !['cloud'].includes(selectedLayer) && (
          <div className={Styles.chartArea}>
            <FontAwesomeIcon
              className={Styles.close}
              icon={faClose}
              onClick={() => selectStation({})}
            />
            <div className={Styles.stationType}>
              {selectedStation.devices.some(({ device_id }) => device_id === '01') && (
                <Tooltip title={'Device type: 01'}>
                  <div onClick={() => selectStation({ deviceId: '01' })}>{'Basic'}</div>
                </Tooltip>
              )}
              {selectedStation.devices.some(({ device_id }) => device_id !== '01') && (
                <Tooltip
                  title={`Device type: ${selectedStation.devices.find(v => v.device_id !== '01')?.device_id}`}
                >
                  <div
                    className={Styles.full}
                    onClick={() =>
                      selectStation({
                        deviceId: selectedStation.devices.find(v => v.device_id !== '01')
                          ?.device_id,
                      })
                    }
                  >
                    {'Full'}
                  </div>
                </Tooltip>
              )}
            </div>
            <div className={Styles.title}>
              <Tooltip title={selectedStation.id}>
                <div
                  className={Styles.stationName}
                  onClick={() => {
                    navigate(`/stn/${selectedStation.id}`)
                  }}
                >
                  {selectedStation.name}
                </div>
              </Tooltip>
              <div className={Styles.element}>{ChartTitle[selectedLayer]}</div>
            </div>
            {!isEmpty(chartData) && (
              <Chart
                className={Styles.chart}
                data={chartData}
                current={selectedTime}
                yAxisUnit={ChartUnit[selectedLayer] ?? ''}
                syncId={'chart'}
                lineColor={ChartLineColors[selectedLayer ?? 'temperature']}
                zoom={chartZoom}
                onMove={setCurrentChartX}
                noMinor
              />
            )}
            {isEmpty(chartData) && <div className={Styles.noData}>{'No data available'}</div>}
          </div>
        )}
        <div className={Styles.date}>{selectedTime?.format('DD MMM HH:mm')}</div>
        <Slider
          timeRange={timeRange}
          stepInMinutes={5}
          selected={selectedTime}
          onSelect={setSelectedTime}
        />
        <div className={Styles.controls}>
          <Button className={Styles.btn} type="primary" size="large" onClick={togglePlay}>
            <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
          </Button>
          <Button
            className={Styles.btn}
            type="primary"
            size="large"
            onClick={() => timeRange && setSelectedTime(timeRange[0])}
          >
            <FontAwesomeIcon icon={faBackwardFast} />
          </Button>
          <Button className={Styles.btn} type="primary" size="large" onClick={goToLatest}>
            <label>{'Now'}</label>
          </Button>
          <Button
            className={Styles.btn}
            type="primary"
            size="large"
            onClick={() => timeRange && setSelectedTime(timeRange[1])}
          >
            <FontAwesomeIcon icon={faForwardFast} />
          </Button>
          <Button className={Styles.btn} type="primary" size="large" onClick={nextPlaySpeed}>
            <FontAwesomeIcon icon={faCirclePlay} />
            <label>{`${playSpeed}x`}</label>
          </Button>
          <Button className={Styles.btn} type="primary" size="large" onClick={toggleDownload}>
            <FontAwesomeIcon icon={faDownload} />
            <label>{'Download'}</label>
          </Button>
          <Select
            className={Styles.downloader}
            open={downloadSelection?.stage === 'range' && downloadSelection?.range !== 'custom'}
            onSelect={v => onDownloadSelectRange(v)}
            options={DownloadOptions}
          />
        </div>
      </div>
      <Modal
        open={downloadSelection?.stage === 'format' || downloadSelection?.range === 'custom'}
        footer={<></>}
        onCancel={toggleDownload}
        centered
        width={300}
      >
        {downloadSelection?.range === 'custom' && (
          <>
            <div>Select Date:</div>
            <DatePicker.RangePicker
              onChange={v => {
                if (!v || !v[0] || !v[1]) return
                onDownloadSelectRange('custom', [v[0], v[1]])
              }}
            />
          </>
        )}
        {downloadSelection?.stage === 'format' && (
          <>
            <div>Select Format:</div>
            <Radio.Group
              options={[
                { label: 'CSV', value: 'csv' },
                { label: 'JSON', value: 'json' },
              ]}
              onChange={e => onDownload(e.target.value)}
              value={undefined}
              optionType="button"
              buttonStyle="solid"
            />
          </>
        )}
      </Modal>
    </div>
  )
}
