import districtKLWE from 'src/assets/districts/klwe.json'
import districtKLWP from 'src/assets/districts/klwp.json'
import districtSHAMOS from 'src/assets/districts/shamos.json'
import { PointFeature } from 'supercluster'

export type MapLayer = 'temperature' | 'humidity' | 'cloud' | 'pressure' | 'wind-direction' | 'wind'
export const MapLayers: MapLayer[] = JSON.parse(process.env.REACT_APP_MAP_LAYERS!)
export const DistrictLayerStyle = {
  'fill-outline-color': '#126094',
  'fill-color': '#82d5ff',
  'fill-opacity': 0.35,
}
export const DistrictLayerHoverStyle = {
  'fill-outline-color': '#126094',
  'fill-color': '#82d5ff',
  'fill-opacity': 0.6,
}
export type District = 'klwe' | 'klwp' | 'shamos'
export const DistrictLayerConfig: Record<District, object> = {
  klwe: districtKLWE,
  klwp: districtKLWP,
  shamos: districtSHAMOS,
}
export const DistrictLayerConfigEntries = Object.entries(DistrictLayerConfig)
export const DistrictName: Record<District, string> = {
  klwe: 'Kowloon East',
  klwp: 'Kowloon Peninsula',
  shamos: 'Shatin & Ma On Shan',
}
export const Districts: District[] = Object.keys(DistrictName) as District[]
export const DistrictNameEntries = Object.entries(DistrictName)
type ObsMarkerDataProps = {
  deviceId: string
  timestamp: number
  obs: Record<MapLayer, number>
  clustered?: boolean
}
export type ObsMarkerData = PointFeature<ObsMarkerDataProps>
export const MapConfig = JSON.parse(process.env.REACT_APP_MAP_DEFAULT_CENTER!)
export const ChartTitle: Partial<Record<MapLayer, string>> = {
  temperature: 'Temperature',
  humidity: 'Humidity',
  pressure: 'Pressure',
  wind: 'Wind',
}
export const ChartUnit: Partial<Record<MapLayer, string>> = {
  temperature: '°C',
  humidity: '%',
  pressure: 'hPa',
  wind: 'km/h',
}
export const ChartLineColors: Partial<Record<MapLayer, string>> = {
  temperature: '#e0b030',
  humidity: '#7070ff',
  pressure: '#f03080',
  wind: '#b03030',
}
export type MapStyle = 'Topo' | 'Bright' | 'Light' | 'Dark'
export const MapStyleJson: Record<MapStyle, string> = {
  Topo: 'https://api.maptiler.com/maps/884a5d89-3a33-4276-bb66-b0e2b8a8ebb0/style.json?key=q7mwu3iiAeubD5fzfI7C',
  Bright:
    'https://api.maptiler.com/maps/0dc64367-2920-4a4b-a94b-d000b0785971/style.json?key=q7mwu3iiAeubD5fzfI7C',
  Light:
    'https://api.maptiler.com/maps/433720f3-7f67-40a0-be02-90f18aebedf5/style.json?key=q7mwu3iiAeubD5fzfI7C',
  Dark: 'https://api.maptiler.com/maps/82102955-ce88-4fa6-98cc-d0d059411b4c/style.json?key=q7mwu3iiAeubD5fzfI7C',
}
export const MapStyles = Object.keys(MapStyleJson) as MapStyle[]
