export const download = async (
  content: string,
  fileName: string,
  contentType: 'application/json' | 'text/csv',
) => {
  const dataToDownload = contentType === 'application/json' ? JSON.stringify(content) : content
  const dataUrl = `data:contentType;charset=utf-8,` + encodeURIComponent(dataToDownload)
  const converted = await fetch(dataUrl)
  const blob = await converted.blob()
  const link = document.createElement('a')
  const href = window.URL.createObjectURL(blob)
  link.href = href
  link.download = fileName
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  window.URL.revokeObjectURL(href)
}
