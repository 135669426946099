import Styles from './App.module.scss'
import { App as AntdApp, ConfigProvider } from 'antd'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { SignInPage } from 'src/pages/sign-in/SignInPage'
import { HomePage } from 'src/pages/home/HomePage'
import { StationDashboardPage } from 'src/pages/station-dashboard/StationDashboardPage'

const router = createBrowserRouter([
  {
    path: `${process.env.PUBLIC_URL}/`,
    element: <HomePage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/stn/:stnId`,
    element: <StationDashboardPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/login`,
    element: <SignInPage />,
  },
])

const App = () => {
  return (
    <div className={Styles.App}>
      <ConfigProvider>
        <AntdApp>
          <RouterProvider router={router} />
        </AntdApp>
      </ConfigProvider>
    </div>
  )
}

export default App
