import axios from 'axios'
import { ChartPt, ObsItem, Station } from './models'
import { MapLayer, MapLayers } from './meta'
import { getToken, saveToken } from './auth'
import { Dayjs } from 'dayjs'

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { 'Content-Type': 'application/json' },
  timeout: 5000,
})

client.interceptors.request.use(config => {
  const token = getToken()
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`
  }
  return config
}, error => {
  if (error.response.status === 401) {
    window.location.replace('/')
  }
  return error
})

export const elmIdLookup: Record<MapLayer, string> = {
  temperature: 'T0',
  humidity: 'RH',
  cloud: 'C0',
  pressure: 'PM',
  wind: 'W0',
  'wind-direction': 'WD',
}

export const login = async ({ username, password }: { username: string; password: string }) => {
  const response = await client.post('/login', {
    username,
    password,
  })
  saveToken(response.data)
  return
}

export const signOut = async () => {
  try {
    await client.get('/signout')
    saveToken(undefined)
  } catch (error) {
  }
  return
}

type GetObsRes = {
  metadata: { stations: Station[] }
  items: ObsItem[]
}

export const getObs = async (elmId: string) => {
  const response = await client.get<GetObsRes>(`/api/gis/${elmId}`, {
    params: { obs: 291 },
  })
  return response.data
}

type GetStationRes = {
  station: Station
  obs: Record<string, ChartPt[]>
}

export const getStation = async (stnId: string): Promise<GetStationRes> => {
  let station: Station | undefined
  const obs: Record<string, ChartPt[]> = {}
  for (let layer of MapLayers) {
    const elmId = elmIdLookup[layer]
    const {
      metadata: { stations },
      items,
    } = await getObs(elmId)
    const stn = stations.find(v => v.id === stnId)
    if (station === undefined) {
      station = stn
    }
    try {
      const readings: ChartPt[] = items
        .map(v => ({
          t: v.timestamp,
          v: v.readings.find(v => v.station_id === stnId)?.value ?? null,
        }))
        .filter(v => v.t !== null && v.v !== null)
        .map(({ t, v }) => ({ t: new Date(t).getTime(), v: parseFloat(v!) }))
      obs[elmId] = readings
    } catch (error) { }
  }
  if (station === undefined) throw new Error('Station not found')
  return { station, obs }
}

export type DownloadFormat = 'csv' | 'json'
export const downloadData = async ({
  element,
  dates: [from, to],
  format,
}: {
  element: string
  dates: [Dayjs, Dayjs]
  format: DownloadFormat
}) => {
  const response = await client.get(`/api/${element}`, {
    params: {
      from: from.startOf('minute').format('YYYYMMDDHHmmss'),
      to: to.startOf('minute').format('YYYYMMDDHHmmss'),
      format,
    },
  })
  return response.data
}
