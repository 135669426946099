import Map, { Marker } from 'react-map-gl/maplibre'
import 'maplibre-gl/dist/maplibre-gl.css'
import maplibregl from 'maplibre-gl'
import Styles from './StationDashboardPage.module.scss'
import { useViewModel } from './StationDashboardPage.vm'
import { ChartLineColors, ChartTitle, ChartUnit, MapLayer, MapStyleJson } from 'src/meta'
import { elmIdLookup } from 'src/api'
import { capitalize, isNil } from 'lodash'
import {
  IconDefinition,
  faCloud,
  faDroplet,
  faMapPin,
  faTachometerAlt,
  faTemperatureQuarter,
  faWind,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Chart } from 'src/components/Chart'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

export const Icon: Partial<Record<MapLayer, IconDefinition>> = {
  temperature: faTemperatureQuarter,
  humidity: faDroplet,
  cloud: faCloud,
  pressure: faTachometerAlt,
  wind: faWind,
}
const ChartsLayer: MapLayer[] = ['temperature', 'humidity', 'pressure', 'wind']
export const StationDashboardPage = () => {
  const { station, obsLookup, latestObsLookup } = useViewModel()

  return (
    <div className={Styles.page}>
      <h1 className={Styles.name}>
        <span>
          {'Integrated urban weather monitoring and data-sharing platform for smart cities'}
        </span>
        <br />
        {station?.name}
      </h1>
      <div className={Styles.upper}>
        <div className={Styles.latestObs}>
          {ChartsLayer.map(layer => {
            const elmId = elmIdLookup[layer]
            const readings = latestObsLookup?.[elmId]?.v
            return (
              <div className={Styles.obs}>
                <h2>{capitalize(layer)}</h2>
                <FontAwesomeIcon className={Styles.icon} icon={Icon[layer]!} />
                {!isNil(readings) && !isNaN(readings) ? (
                  <div className={Styles.value} style={{ color: ChartLineColors[layer] }}>
                    {readings}
                    <span className={Styles.unit}>{ChartUnit[layer]}</span>
                  </div>
                ) : (
                  <div className={Styles.value}>{'-'}</div>
                )}
              </div>
            )
          })}
        </div>
      </div>
      <div className={Styles.below}>
        <div className={Styles.charts}>
          {ChartsLayer.map(layer => {
            const elmId = elmIdLookup[layer]
            return (
              <div className={Styles.chart}>
                <h2>{`${ChartTitle[layer]} Time Series`}</h2>
                <Chart
                  className={Styles.content}
                  data={obsLookup?.[elmId] ?? []}
                  yAxisUnit={ChartUnit[layer] ?? ''}
                  syncId={'chart'}
                  lineColor={ChartLineColors[layer ?? 'temperature']}
                  onMove={v => {}}
                  noMinor
                  grid
                />
              </div>
            )
          })}
        </div>
        <div className={Styles.graphics}>
          <div className={Styles.image}>
            <h2>{'Image'}</h2>
            <Spin
              className={Styles.spin}
              size="large"
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              spinning
            />
            <img src={process.env.REACT_APP_PHOTO_PAHT + `/${station?.id}.jpg`} />
          </div>
          <div className={Styles.map}>
            <h2>{'Location'}</h2>
            <Spin
              className={Styles.spin}
              size="large"
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              spinning
            />
            <Map
              mapLib={maplibregl}
              mapStyle={MapStyleJson.Topo}
              zoom={14}
              latitude={station?.location.latitude ?? 22.3}
              longitude={station?.location.longitude ?? 114.2}
              attributionControl={false}
              interactive={false}
            >
              {station && (
                <Marker
                  longitude={station.location.longitude}
                  latitude={station.location.latitude}
                  anchor="bottom"
                  style={{ zIndex: 2 }}
                >
                  <div className={Styles.marker}>
                    <FontAwesomeIcon className={Styles.icon} icon={faMapPin} />
                  </div>
                </Marker>
              )}
            </Map>
          </div>
        </div>
      </div>
    </div>
  )
}
