import { MapLayer, ObsMarkerData } from '../../meta'
import { Marker } from 'react-map-gl/maplibre'
import classNames from 'classnames'
import Styles from './ObsMarker.module.scss'
import { isNil } from 'lodash'
import imgN from 'src/assets/wind-dir/n.png'
import imgNE from 'src/assets/wind-dir/ne.png'
import imgE from 'src/assets/wind-dir/e.png'
import imgSE from 'src/assets/wind-dir/se.png'
import imgS from 'src/assets/wind-dir/s.png'
import imgSW from 'src/assets/wind-dir/sw.png'
import imgW from 'src/assets/wind-dir/w.png'
import imgNW from 'src/assets/wind-dir/nw.png'
import imgC from 'src/assets/wind-dir/c.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { useCallback } from 'react'
import { Spin, Tooltip } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

type WindDirection = 'N' | 'NE' | 'E' | 'SE' | 'S' | 'SW' | 'W' | 'NW' | undefined

const getWindDirection = (deg?: number): WindDirection => {
  if (isNil(deg) || isNaN(deg)) return undefined
  if (deg >= 337.5 || deg < 22.5) return 'N'
  else if (deg < 67.5) return 'NE'
  else if (deg < 112.5) return 'E'
  else if (deg < 157.5) return 'SE'
  else if (deg < 202.5) return 'S'
  else if (deg < 247.5) return 'SW'
  else if (deg < 292.5) return 'W'
  else if (deg < 337.5) return 'NW'
}

const getWDImage = (deg?: number) => {
  const wd = getWindDirection(deg)
  switch (wd) {
    case 'N':
      return imgN
    case 'NE':
      return imgNE
    case 'E':
      return imgE
    case 'SE':
      return imgSE
    case 'S':
      return imgS
    case 'SW':
      return imgSW
    case 'W':
      return imgW
    case 'NW':
      return imgNW
    default:
      return imgC
  }
}

export const ObsMarker = ({
  data,
  selectedLayer,
  isSelected,
  onClick,
  onCancel,
}: {
  data: ObsMarkerData
  selectedLayer: MapLayer
  isSelected: boolean
  onClick: (data: ObsMarkerData) => any
  onCancel: (data: ObsMarkerData) => any
}) => {
  const {
    id,
    geometry: { coordinates: [lng, lat] },
    properties: { obs, clustered },
  } = data

  const shouldWDShown = (
    !clustered &&
    selectedLayer === 'wind' &&
    (!isNaN(obs[selectedLayer]) || !isNaN(obs['wind-direction']))
  )

  const Photo = useCallback(() => {
    const path = process.env.REACT_APP_PHOTO_PAHT
    if (!path) return <></>

    const src = `${path}/${id}.jpg`
    return <Tooltip title={'click to open'} placement='right'>
      <img
        src={src}
        className={Styles.photo}
        onClick={() => window.open(src, '_blank')} />
    </Tooltip>
  }, [id])

  return (
    <Marker longitude={lng} latitude={lat} anchor="center" style={{ zIndex: isSelected ? 2 : 1 }}>
      <div
        className={classNames(
          Styles.marker,
          !isSelected && !isNaN(obs[selectedLayer]) && clustered && Styles.clustered,
          Styles[selectedLayer],
        )}
      >
        <img
          className={classNames(Styles.windDir, shouldWDShown && Styles.shown)}
          src={getWDImage(obs['wind-direction'])}
        />
        <div className={Styles.point} onClick={() => onClick(data)} />
        <label
          className={classNames(shouldWDShown && Styles.wdShown)}
          onClick={() => onClick(data)}
        >
          {!isNaN(obs[selectedLayer]) ? obs[selectedLayer].toFixed(1) : '-'}
        </label>
        {isSelected && (
          <div className={Styles.tooltip}>
            <FontAwesomeIcon
              className={Styles.close}
              icon={faClose}
              onClick={() => onCancel(data)}
            />
            {process.env.REACT_APP_PHOTO_PAHT && (
              <div className={Styles.container}>
                <Spin
                  className={Styles.spin}
                  size="large"
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                  spinning
                />
                <Photo />
              </div>
            )}
            <div className={Styles.pin} />
          </div>
        )}
      </div>
    </Marker>
  )
}
