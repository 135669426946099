import { useCallback, useEffect, useState } from 'react'
import { MapRef } from 'react-map-gl/maplibre'
import { MapConfig, MapLayer, MapStyle, ObsMarkerData } from '../../meta'
import { District, DistrictLayerConfig } from '../../meta'
import { DownloadFormat, downloadData, elmIdLookup, getObs, signOut as signOutByApi } from 'src/api'
import { ChartPt, ObsItem, Station } from 'src/models'
import dayjs, { Dayjs } from 'dayjs'
import _, { clamp, debounce, isNil, maxBy, set, uniqBy } from 'lodash'
import Supercluster from 'supercluster'
import { usePlayer } from 'src/hooks/usePlayer'
import { useNavigate } from 'react-router-dom'
import { download } from 'src/utils'
import { shouldSignIn } from 'src/auth'

type CtrBtnType = 'zoom' | 'zoom-in' | 'zoom-out' | 'direction' | 'locate' | '3D'
export type DownloadRange = 'today' | 'yesterday' | 'last-24h' | 'last-7d' | 'custom'
const supercluster = new Supercluster({ radius: 24, maxZoom: 24 })

const clusterMarkers = (markers: ObsMarkerData[], layer: MapLayer, zoomLevel: number) => {
  // cluster markers only if obs value is valid
  const sc = supercluster.load(
    markers.filter(v => {
      const value = v.properties.obs[layer]
      return !isNil(value) && !isNaN(value)
    }),
  )
  const clusters = sc.getClusters([113, 21, 115, 24], zoomLevel)
  const _markers: ObsMarkerData[] = markers.map(v => ({
    ...v,
    properties: {
      ...v.properties,
      clustered: !clusters.some(({ id }) => id === v.id),
    },
  }))
  return _markers
}

const groupMultiDevices = (markers: ObsMarkerData[], layer: MapLayer) => {
  const stnIds = uniqBy(markers, v => v.id).map(v => v.id)
  const _markers: ObsMarkerData[] = stnIds.map(id => {
    const stnMarkers = markers.filter(v => v.id === id)
    const validStnMarkers = stnMarkers.filter(v => !isNaN(v.properties.obs[layer]))
    const sorted = validStnMarkers.sort((a, b) =>
      b.properties.deviceId.localeCompare(a.properties.deviceId),
    )
    return sorted[0] ?? stnMarkers[0]
  })
  return _markers
}

const mergeMarkers = (
  data: ObsMarkerData[],
  obsItems: ObsItem[],
  stations: Station[],
  layer: MapLayer,
) => {
  const markers = data.concat([])
  for (let item of obsItems) {
    const { timestamp: tStr, readings } = item
    const timestamp = dayjs(tStr).toDate().getTime()
    for (let { station_id, value, device_id } of readings) {
      // filter for unique station_id and timestamp
      const index = data.findIndex(
        v =>
          v.id === station_id &&
          v.properties.timestamp === timestamp &&
          v.properties.deviceId === device_id,
      )
      if (index === -1) {
        const station = stations.find(v => v.id === station_id)
        if (!station) continue

        markers.push({
          id: station_id,
          geometry: {
            coordinates: [station.location.longitude, station.location.latitude],
            type: 'Point',
          },
          type: 'Feature',
          properties: {
            timestamp,
            obs: { [layer]: parseFloat(value) },
            deviceId: device_id,
          },
        } as ObsMarkerData)
      } else {
        set(markers[index], `properties.obs.${layer}`, parseFloat(value))
      }
    }
  }
  return markers
}

const filterMarkersForTime = ({ markers, time }: { markers: ObsMarkerData[]; time?: Dayjs }) => {
  const timestamp = time
    ? time.unix() * 1000
    : maxBy(markers, v => v.properties.timestamp)?.properties?.timestamp ?? Date.now()
  const _markers = markers.filter(v => v.properties.timestamp === timestamp)
  return _markers
}

const getClosestFiveMin = (time: Dayjs) => {
  const min = time.subtract(4, 'minutes').minute()
  const min5 = Math.floor(min / 5) * 5
  return time.minute(min5).second(0).millisecond(0)
}

type PlaySpeed = 1 | 2 | 0.5
const playSpeeds: PlaySpeed[] = [0.5, 1, 2]
export const useViewModel = () => {
  const navigate = useNavigate()
  const [selectedLayer, setSelectLayer] = useState<MapLayer>('temperature')
  const [zoomLevel, setZoomLevel] = useState<number>(11)
  const [rotateDegree, setRotateDegree] = useState(0)
  const [tiltDegree, setTiltDegree] = useState(0)
  const [hoveringFeature, setHoveringFeature] = useState<string | undefined>()
  const [stations, setStations] = useState<Station[]>()
  const [timeRange, setTimeRange] = useState<[Dayjs, Dayjs] | undefined>()
  const [{ obsMarkersShown, obsMarkers }, setObsMarkers] = useState<{
    obsMarkersShown?: ObsMarkerData[],
    obsMarkers?: ObsMarkerData[]
  }>({})
  const [selectedStation, setSelectedStation] = useState<Station | undefined>()
  const [downloadSelection, setDownloadSelection] = useState<
    | {
      stage: 'range' | 'format'
      range?: DownloadRange
      format?: DownloadFormat
      dates?: [Dayjs, Dayjs]
    }
    | undefined
  >()
  const [chartData, setChartData] = useState<ChartPt[]>([])
  const [mapStyle, setMapStyle] = useState<MapStyle>('Topo')
  const [chartZoom, setChartZoom] = useState<{ x: number; scale: number } | undefined>()
  const [_currentChartX, setCurrentChartX] = useState<number | undefined>()
  const [playSpeed, setPlaySpeed] = useState<PlaySpeed>(1)
  const {
    togglePlay,
    isPlaying,
    current: selectedTime,
    setCurrent: setSelectedTime,
  } = usePlayer({
    step: 5,
    stepUnit: 'minute',
    interval: 500 / playSpeed,
    timeRange: [
      timeRange?.[0] ?? getClosestFiveMin(dayjs()),
      timeRange?.[1] ?? getClosestFiveMin(dayjs()),
    ],
  })

  const updateObsMarkersShown = ({
    markers: newMarkers,
    time,
    layer,
    zoomLevel,
  }: {
    markers?: ObsMarkerData[]
    time?: Dayjs
    layer: MapLayer
    zoomLevel?: number
  }) => {
    setObsMarkers(({ obsMarkers }) => {
      const markers = newMarkers ?? obsMarkers ?? []
      const msT = filterMarkersForTime({ markers, time })
      const msC = zoomLevel ? clusterMarkers(msT, layer, zoomLevel) : msT
      const msG = groupMultiDevices(msC, layer)
      return { obsMarkersShown: msG, obsMarkers: markers }
    })
  }

  const selectStation = ({
    stationId,
    layer,
    deviceId,
  }: {
    stationId?: string
    deviceId?: string
    layer?: MapLayer
  }) => {
    const shouldCancel = !stationId && !deviceId
    const id = stationId ?? selectedStation?.id
    setSelectedStation(
      shouldCancel ? undefined : stations?.find(v => v.id === id) ?? selectedStation,
    )
    setChartData(
      _(obsMarkers)
        ?.filter(v => v.id === id && (!deviceId || v.properties.deviceId === deviceId))
        .map(v => ({
          t: v.properties.timestamp,
          v: v.properties.obs[layer ?? selectedLayer],
        }))
        .filter(v => !isNaN(v.v))
        .sortBy(v => v.t)
        .value() ?? [],
    )
  }

  const clickCtrBtn = (type: CtrBtnType | MapLayer, map?: MapRef) => {
    switch (type) {
      case 'zoom-in':
        return map?.zoomIn()
      case 'zoom-out':
        return map?.zoomOut()
      case 'direction':
        return map?.resetNorth()
      case 'locate':
        return navigator.geolocation.getCurrentPosition(
          ({ coords: { latitude: lat, longitude: lng } }) => {
            map?.flyTo({ center: { lat, lng } })
          },
        )
      case '3D':
        return setTiltDegree(v => {
          let newV = 0
          if (v < 30) newV = 30
          else if (v < 45) newV = 45
          else if (v < 60) newV = 60
          map?.setPitch(newV)
          return newV
        })
      default:
        const ly = type as MapLayer
        setSelectLayer(ly)
        selectStation({ stationId: selectedStation?.id, layer: ly })
    }
  }
  const onZoom = useCallback(debounce((z: number) => {
    setZoomLevel(z)
    updateObsMarkersShown({
      time: selectedTime,
      layer: selectedLayer,
      zoomLevel: z,
    })
  }, 200), [])
  const onRotate = useCallback((d: number) => setRotateDegree(d), [setRotateDegree])
  const onTilt = useCallback((d: number) => setTiltDegree(d), [setTiltDegree])
  const goToDistrict = useCallback((district: District | null, map?: MapRef) => {
    if (!district) return
    const center = (DistrictLayerConfig[district] as any)?.properties
    const lat = center.lat
    const lng = center.lon
    map?.flyTo({ center: { lat, lng }, zoom: 13 })
  }, [])

  const toggleDownload = () => {
    setDownloadSelection(v => (!v ? { stage: 'range' } : undefined))
  }

  const onDownloadSelectRange = (range: DownloadRange, dates?: [Dayjs, Dayjs]) => {
    const shouldGoToFormat = range !== 'custom' || dates
    const stage = shouldGoToFormat ? 'format' : 'range'
    setDownloadSelection({ stage, range, dates })
  }

  const getDownloadDates = (range?: DownloadRange): [Dayjs, Dayjs] | undefined => {
    switch (range) {
      case 'today':
        return [dayjs().startOf('day'), dayjs().endOf('day')]
      case 'yesterday':
        return [dayjs().subtract(1, 'day').startOf('day'), dayjs().subtract(1, 'day').endOf('day')]
      case 'last-24h':
        return [dayjs().subtract(1, 'day'), dayjs()]
      case 'last-7d':
        return [dayjs().subtract(7, 'day'), dayjs()]
      default:
        return undefined
    }
  }

  const onDownload = async (format: DownloadFormat) => {
    toggleDownload()
    const dates = getDownloadDates(downloadSelection?.range) ?? downloadSelection?.dates
    const element = elmIdLookup[selectedLayer]
    if (dates && element) {
      const data = await downloadData({ element, dates, format })
      const filename = `${element}-${dates[0].format('YYYYMMDD')}-${dates[1].format(
        'YYYYMMDD',
      )}.${format}`
      download(data, filename, format === 'csv' ? 'text/csv' : 'application/json')
    }
  }

  const loadLayer = async (layer: MapLayer) => {
    const elmId = elmIdLookup[layer]
    const {
      metadata: { stations },
      items,
    } = await getObs(elmId)
    return [stations, items] as [Station[], ObsItem[]]
  }

  const nextPlaySpeed = useCallback(() => {
    setPlaySpeed(v => playSpeeds[(playSpeeds.indexOf(v) + 1) % playSpeeds.length])
  }, [setPlaySpeed])

  const load = async () => {
    let markers: ObsMarkerData[] = []
    let _stations: Station[] = []
    const layers: MapLayer[] = ['temperature', 'humidity', 'pressure', 'wind', 'wind-direction']
    const res = await Promise.allSettled(layers.map(loadLayer))
    for (let i = 0; i < res.length; i++) {
      if (res[i].status === 'rejected') continue

      const [stns, itms] = (res[i] as any).value as [Station[], ObsItem[]]
      _stations = uniqBy([..._stations, ...stns], 'id')
      markers = mergeMarkers(markers, itms, _stations, layers[i])
    }
    setSelectedTime(
      t => t ?? dayjs(maxBy(markers, v => v.properties.timestamp)?.properties?.timestamp),
    )
    updateObsMarkersShown({
      markers,
      layer: selectedLayer,
      zoomLevel: zoomLevel ?? MapConfig.zoom,
    })
    setStations(_stations)

    const maxTime =
      maxBy(markers, m => m.properties.timestamp)?.properties?.timestamp ??
      getClosestFiveMin(dayjs())
    setTimeRange([dayjs(maxTime).subtract(1, 'day'), dayjs(maxTime)] as [Dayjs, Dayjs])
  }

  const zoomChartWhenFocus = (e: WheelEvent) => {
    if (_currentChartX === undefined) return
    e.preventDefault()

    setChartZoom(v => {
      const _scale = (v?.scale ?? 1) * (e.deltaY > 0 ? 0.8 : 1.2)
      return { x: _currentChartX, scale: clamp(_scale, 0.1, 1) }
    })
  }

  const goToLatest = () => {
    setSelectedTime(getClosestFiveMin(dayjs()))
  }

  const signOut = async () => {
    await signOutByApi()
    navigate('/login')
  }

  useEffect(() => {
    if (shouldSignIn()) {
      navigate('/login')
      return
    }

    load()
    const timer = setInterval(() => {
      if (dayjs().minute() % 5 === 0) {
        load()
      }
    }, 60_000)
    return () => clearInterval(timer)
  }, [])

  useEffect(() => {
    updateObsMarkersShown({
      time: selectedTime,
      layer: selectedLayer,
      zoomLevel,
    })
  }, [selectedTime])

  return {
    chartData,
    obsMarkersShown,
    selectedLayer,
    hoveringFeature,
    zoomLevel,
    rotateDegree,
    tiltDegree,
    selectedTime,
    selectedStation,
    mapStyle,
    timeRange,
    playSpeed,
    isPlaying,
    downloadSelection,
    chartZoom,
    setCurrentChartX,
    zoomChartWhenFocus,
    goToLatest,
    toggleDownload,
    onDownloadSelectRange,
    onDownload,
    togglePlay,
    setMapStyle,
    selectStation,
    setSelectedTime,
    onZoom,
    onRotate,
    onTilt,
    clickCtrBtn,
    setHoveringFeature,
    goToDistrict,
    nextPlaySpeed,
    signOut,
  }
}
